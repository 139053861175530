function fetchImgData(){

	  var data = [ 
		{
			"index":1,
			 "description":"" 
		},
		{
			"index":2,
			 "description":"" 
		},
		{
			"index":3,
			 "description":"" 
		},
		{
			"index":4,
			"description":"" 
		},
		{
			"index":5,
			"description":"" 
		},
		{
			"index":6,
			"description":"" 
		},
		{
			"index":7,
			"description":"" 
		},
		{
			"index":8,
			"description":"" 
		},
		{
			"index":9,
			"description":"" 
		},
		{
			"index":10,
			"description":"" 
		},
		{
			"index":11,
			"description":"" 
		},
		{
			"index":12,
			"description":"" 
		},
		{
			"index":13,
			"description":"" 
		},
		{
			"index":14,
			"description":"" 
		},
		{
			"index":15,
			"description":"" 
		},
		{
			"index":16,
			"description":"" 
		},
		{
			"index":17,
			"description":"" 
		},
		{
			"index":18,
			"description":"" 
		},
		{
			"index":19,
			"description":"" 

		},
		{
			"index":20,
			"description":"" 
		},
		{
			"index":21,
			"description":"" 
		},
		{
			"index":22,
			"description":"" 
		},
		{
			"index":23,
			"description":"" 
		},
		{
			"index":24,
			"description":"" 
		},
		{
			"index":25,
			"description":"" 
		},
		{
			"index":26,
			"description":"" 
		},
		{
			"index":27,
			"description":"" 
		},
		{
			"index":28,
			"description":"" 
		},
		{
			"index":29,
			"description":"" 
		},
		{
			"index":30,
			"description":"" 
		},
		{
			"index":31,
			"description":"" 
		},
		{
			"index":32,
			"description":"" 
		},
		{
			"index":33,
			"description":"" 
		}

		
	   ];
	   
	   
	   $.each(data, function(i, item) {
		   
		   var image = '';
		   var count = i + 1

		   image += '<bild-element class="slide">';
		   image += '<counter>' + count + '/33</counter>';
		   image += '<img src="assets/img/' + count + '.jpg" alt="' + data[i].description + '" srcset="assets/img/' + count + '@350w.jpg 350w, assets/img/' + count + '@600w.jpg 600w, assets/img/' + count + '@900w.jpg 900w, assets/img/' + count + '@1100w.jpg 1100w" sizes="(max-width: 350px) 350px, (max-width: 600px) 600px, (max-width: 900px) 900px, 1100px">';
		   image += '</bild-element>';

		   $('bilder').append(image);
	   });

	   
		$("bild-element").first().removeClass("slide").addClass("current-slide")


}